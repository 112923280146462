$(function(){
    const init =()=> {
        formEmail()
    }
    const formEmail =()=> {
        $('#form').on('submit',function(e) {
            e.preventDefault()
            var d = $( this ).serialize()
            console.log(d)
            $.ajax({
                type: "POST",
                url: "response.php",
                data: d,
                success: function(data){
                    thankYou()
                }
            })
        })
    }
    const thankYou =()=> {
        $('.landing').fadeOut();
        $('.thank-you').fadeIn();
    }
    return init()
})

const confirmEmail =()=> {
    let email = $('#email').val()
    let confirmEmail = $('#confirm-email').val()
    if (email !== confirmEmail) {
        $('#confirm-email').alert()
        $('#email-fail').addClass('show')
    } else {
        $('#email-fail').removeClass('show')
    }
}